<template>
 <div>
   <div class="search-list-view">
     <van-list
       v-model="loading"
       :finished="finished"
       finished-text="没有更多了"
       @load="onLoad"
       :offset="offset"
     >
       <div v-for="item in list" :key="item" :title="item" class="search-list">
         <div>
           <svg class="icon icon_huafeiliuliangchongzhi" aria-hidden="true"> <use xlink:href="#icon-xianjin_huaban1"></use></svg>
         </div>
         <div>
           <p>{{item.account.replace(/(\d{3})\d*(\d{4})/,'$1****$2')}}</p>
           <span>{{item.remark.length>5?item.remark.substring(0,4)+'..':item.remark}}</span>
         </div>
         <div>
           <p v-if="item.type=='1'">收入 {{item.money.toFixed(2)}}元</p>
           <p v-if="item.type=='0'">支出 {{item.money.toFixed(2)}}元</p>
           <span>{{item.addTime}}</span>
         </div>
       </div>
     </van-list>
   </div>
 </div>
</template>

<script>
export default {
   data() {
    return {
      list: [],
      loading: false,
      finished: false,
      phoneNumberCode:"",
      page:1,
      limit:20,
      offset:"-10"
    };
  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:#f5f5f5')
  },
  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  },
  methods:{
    onLoad() {
          if(this.loading){
            return;
          }  
          this.loading = true;

            console.log("第几次加载",this.page);
            this.axios.post('/app/query/notes', {
              page:this.page,
              limit:this.limit
            }).then((response) => {
              this.list = [...this.list,...response.list];
              console.log(response);
              this.loading = false;
              if(response.list.length<response.pageSize){
                this.finished = true;
              }
              this.page = this.page+1;
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
      }
  }
};
</script>

<style scoped>
.img-icon {
  height: 20px;
}
.search-list-view{
  min-height: 85vh;
  background: #ffffff;
  margin: 10px;
  border-radius: 20px;
  padding: 15px;
  height: 100%;
}
.search-list-view .search-list{
  display: flex;
  margin: 20px 0;
}
.search-list-view .search-list div{
  vertical-align: middle;
}
.search-list-view .search-list div p{
  vertical-align: middle;
  margin: 3px 0;
}
.search-list-view .search-list div:nth-child(2){
  margin-left: 15px;
}
.search-list-view .search-list div:nth-child(2) span,.search-list-view .search-list div:nth-child(3) span{
  font-size: 13px;
  color: #cecece;
}
.search-list-view .search-list div:nth-child(3){
  position: absolute;
  right: 20px;
}
.search-list-view .search-list div:nth-child(3) p{
  color: #2BD67E;
  text-align: right;
}
</style>
